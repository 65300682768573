import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _28a455b4 = () => interopDefault(import('../pages/belepes/index.vue' /* webpackChunkName: "pages/belepes/index" */))
const _3aab0c0c = () => interopDefault(import('../pages/elfelejtett-jelszo/index.vue' /* webpackChunkName: "pages/elfelejtett-jelszo/index" */))
const _6a80f4fe = () => interopDefault(import('../pages/fiokom/index.vue' /* webpackChunkName: "pages/fiokom/index" */))
const _60da18b4 = () => interopDefault(import('../pages/folknaptar/index.vue' /* webpackChunkName: "pages/folknaptar/index" */))
const _1a08fe54 = () => interopDefault(import('../pages/folkszemle/index.vue' /* webpackChunkName: "pages/folkszemle/index" */))
const _338f9733 = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _8d3375ae = () => interopDefault(import('../pages/galeria/index.vue' /* webpackChunkName: "pages/galeria/index" */))
const _7f3dabd4 = () => interopDefault(import('../pages/radio-stream/index.vue' /* webpackChunkName: "pages/radio-stream/index" */))
const _910a0974 = () => interopDefault(import('../pages/regisztracio/index.vue' /* webpackChunkName: "pages/regisztracio/index" */))
const _4a48862e = () => interopDefault(import('../pages/tallozo/index.vue' /* webpackChunkName: "pages/tallozo/index" */))
const _4ef40caf = () => interopDefault(import('../pages/tamogatas/index.vue' /* webpackChunkName: "pages/tamogatas/index" */))
const _edc67746 = () => interopDefault(import('../pages/admin/albumok/index.vue' /* webpackChunkName: "pages/admin/albumok/index" */))
const _700d7e89 = () => interopDefault(import('../pages/admin/beallitasok/index.vue' /* webpackChunkName: "pages/admin/beallitasok/index" */))
const _d853fe92 = () => interopDefault(import('../pages/admin/dalszovegek/index.vue' /* webpackChunkName: "pages/admin/dalszovegek/index" */))
const _53f9a6b9 = () => interopDefault(import('../pages/admin/eloadok/index.vue' /* webpackChunkName: "pages/admin/eloadok/index" */))
const _0263dd5c = () => interopDefault(import('../pages/admin/esemenyek/index.vue' /* webpackChunkName: "pages/admin/esemenyek/index" */))
const _64bb20a8 = () => interopDefault(import('../pages/admin/felhasznalok/index.vue' /* webpackChunkName: "pages/admin/felhasznalok/index" */))
const _28fb80ee = () => interopDefault(import('../pages/admin/felvetelek/index.vue' /* webpackChunkName: "pages/admin/felvetelek/index" */))
const _d602fb58 = () => interopDefault(import('../pages/admin/folkszemle/index.vue' /* webpackChunkName: "pages/admin/folkszemle/index" */))
const _4e379333 = () => interopDefault(import('../pages/admin/forum/index.vue' /* webpackChunkName: "pages/admin/forum/index" */))
const _b2e02a64 = () => interopDefault(import('../pages/admin/oldalak/index.vue' /* webpackChunkName: "pages/admin/oldalak/index" */))
const _26aa5ce6 = () => interopDefault(import('../pages/admin/radio/index.vue' /* webpackChunkName: "pages/admin/radio/index" */))
const _29168e2e = () => interopDefault(import('../pages/admin/tallozo/index.vue' /* webpackChunkName: "pages/admin/tallozo/index" */))
const _06195e8b = () => interopDefault(import('../pages/admin/tamogatasok/index.vue' /* webpackChunkName: "pages/admin/tamogatasok/index" */))
const _368aaa28 = () => interopDefault(import('../pages/admin/zeneialbumok/index.vue' /* webpackChunkName: "pages/admin/zeneialbumok/index" */))
const _2683e62a = () => interopDefault(import('../pages/esemeny-emlekezteto/leiratkozas.vue' /* webpackChunkName: "pages/esemeny-emlekezteto/leiratkozas" */))
const _3e4f22da = () => interopDefault(import('../pages/fiokom/albumaim/index.vue' /* webpackChunkName: "pages/fiokom/albumaim/index" */))
const _524690da = () => interopDefault(import('../pages/fiokom/esemenyeim/index.vue' /* webpackChunkName: "pages/fiokom/esemenyeim/index" */))
const _8490bb04 = () => interopDefault(import('../pages/fiokom/profilom/index.vue' /* webpackChunkName: "pages/fiokom/profilom/index" */))
const _df5da60e = () => interopDefault(import('../pages/folknaptar/naptar/index.vue' /* webpackChunkName: "pages/folknaptar/naptar/index" */))
const _6d69b20b = () => interopDefault(import('../pages/folkszemle/idezetgyujtemeny/index.vue' /* webpackChunkName: "pages/folkszemle/idezetgyujtemeny/index" */))
const _e95338f0 = () => interopDefault(import('../pages/folkszemle/tudasproba/index.vue' /* webpackChunkName: "pages/folkszemle/tudasproba/index" */))
const _b05642d0 = () => interopDefault(import('../pages/hirlevel/leiratkozas.vue' /* webpackChunkName: "pages/hirlevel/leiratkozas" */))
const _382107fe = () => interopDefault(import('../pages/mediatar/albumok/index.vue' /* webpackChunkName: "pages/mediatar/albumok/index" */))
const _517c465b = () => interopDefault(import('../pages/mediatar/dalszovegek/index.vue' /* webpackChunkName: "pages/mediatar/dalszovegek/index" */))
const _1aeab565 = () => interopDefault(import('../pages/mediatar/felvetelek/index.vue' /* webpackChunkName: "pages/mediatar/felvetelek/index" */))
const _61d1d8e4 = () => interopDefault(import('../pages/radio/musorujsag/index.vue' /* webpackChunkName: "pages/radio/musorujsag/index" */))
const _4fbcc103 = () => interopDefault(import('../pages/tamogatas/sikeres-tranzakcio/index.vue' /* webpackChunkName: "pages/tamogatas/sikeres-tranzakcio/index" */))
const _ff51f7ca = () => interopDefault(import('../pages/admin/eroforrasok/eloadasmodok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/eloadasmodok/index" */))
const _1743fa31 = () => interopDefault(import('../pages/admin/eroforrasok/etnikumok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/etnikumok/index" */))
const _0b3db89e = () => interopDefault(import('../pages/admin/eroforrasok/forrasok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/forrasok/index" */))
const _f38be536 = () => interopDefault(import('../pages/admin/eroforrasok/gyujtok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/gyujtok/index" */))
const _7ed5863b = () => interopDefault(import('../pages/admin/eroforrasok/jatszhatosag/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/jatszhatosag/index" */))
const _75426db7 = () => interopDefault(import('../pages/admin/eroforrasok/kiadok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/kiadok/index" */))
const _5ffa4d0a = () => interopDefault(import('../pages/admin/eroforrasok/megyek/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/megyek/index" */))
const _5e28387f = () => interopDefault(import('../pages/admin/eroforrasok/mufajok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/mufajok/index" */))
const _6e717c21 = () => interopDefault(import('../pages/admin/eroforrasok/szovegforrasok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/szovegforrasok/index" */))
const _42ed22c6 = () => interopDefault(import('../pages/admin/eroforrasok/tajegysegek/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/tajegysegek/index" */))
const _213b486d = () => interopDefault(import('../pages/admin/eroforrasok/telepulesek/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/telepulesek/index" */))
const _5cfffdc2 = () => interopDefault(import('../pages/admin/eroforrasok/temak/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/temak/index" */))
const _6b00130a = () => interopDefault(import('../pages/admin/esemenyek/listak/index.vue' /* webpackChunkName: "pages/admin/esemenyek/listak/index" */))
const _58a6a3ea = () => interopDefault(import('../pages/admin/folkszemle/idezetgyujtemeny/index.vue' /* webpackChunkName: "pages/admin/folkszemle/idezetgyujtemeny/index" */))
const _a2f340f0 = () => interopDefault(import('../pages/admin/folkszemle/tudasproba/index.vue' /* webpackChunkName: "pages/admin/folkszemle/tudasproba/index" */))
const _1d5a462a = () => interopDefault(import('../pages/admin/radio/szinkron/index.vue' /* webpackChunkName: "pages/admin/radio/szinkron/index" */))
const _188cef52 = () => interopDefault(import('../pages/admin/esemenyek/listak/lista/_id.vue' /* webpackChunkName: "pages/admin/esemenyek/listak/lista/_id" */))
const _c4c5c3fe = () => interopDefault(import('../pages/admin/folkszemle/idezetgyujtemeny/idezet/_id.vue' /* webpackChunkName: "pages/admin/folkszemle/idezetgyujtemeny/idezet/_id" */))
const _37d0069f = () => interopDefault(import('../pages/admin/folkszemle/tudasproba/kerdes/_id.vue' /* webpackChunkName: "pages/admin/folkszemle/tudasproba/kerdes/_id" */))
const _c59a5750 = () => interopDefault(import('../pages/admin/eloadok/eloado/_id.vue' /* webpackChunkName: "pages/admin/eloadok/eloado/_id" */))
const _7a129e28 = () => interopDefault(import('../pages/admin/felhasznalok/felhasznalo/_id.vue' /* webpackChunkName: "pages/admin/felhasznalok/felhasznalo/_id" */))
const _1821865f = () => interopDefault(import('../pages/admin/folkszemle/cikk/_id.vue' /* webpackChunkName: "pages/admin/folkszemle/cikk/_id" */))
const _09f60539 = () => interopDefault(import('../pages/admin/oldalak/oldal/_id.vue' /* webpackChunkName: "pages/admin/oldalak/oldal/_id" */))
const _828968ac = () => interopDefault(import('../pages/admin/tallozo/cikk/_id.vue' /* webpackChunkName: "pages/admin/tallozo/cikk/_id" */))
const _0a7b0c70 = () => interopDefault(import('../pages/admin/zeneialbumok/album/_id.vue' /* webpackChunkName: "pages/admin/zeneialbumok/album/_id" */))
const _494bb8bc = () => interopDefault(import('../pages/admin/dalszovegek/dalszoveg/_id/_recording.vue' /* webpackChunkName: "pages/admin/dalszovegek/dalszoveg/_id/_recording" */))
const _58496a68 = () => interopDefault(import('../pages/admin/felvetelek/felvetel/_id/_album.vue' /* webpackChunkName: "pages/admin/felvetelek/felvetel/_id/_album" */))
const _81144b48 = () => interopDefault(import('../pages/folkszemle/idezetgyujtemeny/idezet/_id/_name.vue' /* webpackChunkName: "pages/folkszemle/idezetgyujtemeny/idezet/_id/_name" */))
const _49e30ec1 = () => interopDefault(import('../pages/admin/forum/_category/index.vue' /* webpackChunkName: "pages/admin/forum/_category/index" */))
const _f1134410 = () => interopDefault(import('../pages/admin/radio/_id/index.vue' /* webpackChunkName: "pages/admin/radio/_id/index" */))
const _77b09349 = () => interopDefault(import('../pages/fiokom/album/_id.vue' /* webpackChunkName: "pages/fiokom/album/_id" */))
const _d83ee1d8 = () => interopDefault(import('../pages/radio/musorujsag/_day/index.vue' /* webpackChunkName: "pages/radio/musorujsag/_day/index" */))
const _9cd80f3c = () => interopDefault(import('../pages/tamogatas/sikertelen-tranzakcio/_type.vue' /* webpackChunkName: "pages/tamogatas/sikertelen-tranzakcio/_type" */))
const _70e0b4eb = () => interopDefault(import('../pages/admin/forum/_category/_theme.vue' /* webpackChunkName: "pages/admin/forum/_category/_theme" */))
const _0f85b8f8 = () => interopDefault(import('../pages/fiokom/esemeny/_id/_klon.vue' /* webpackChunkName: "pages/fiokom/esemeny/_id/_klon" */))
const _4f5c7576 = () => interopDefault(import('../pages/folknaptar/esemeny/_id/_name/index.vue' /* webpackChunkName: "pages/folknaptar/esemeny/_id/_name/index" */))
const _0ffd549e = () => interopDefault(import('../pages/folknaptar/listak/_slug/_year.vue' /* webpackChunkName: "pages/folknaptar/listak/_slug/_year" */))
const _a042e78c = () => interopDefault(import('../pages/folkszemle/cikk/_id/_name.vue' /* webpackChunkName: "pages/folkszemle/cikk/_id/_name" */))
const _5fd69f7a = () => interopDefault(import('../pages/forum/tema/_id/_name.vue' /* webpackChunkName: "pages/forum/tema/_id/_name" */))
const _ba01e968 = () => interopDefault(import('../pages/galeria/album/_id/_name.vue' /* webpackChunkName: "pages/galeria/album/_id/_name" */))
const _571e2e62 = () => interopDefault(import('../pages/galeria/album/_id/_name/index.vue' /* webpackChunkName: "pages/galeria/album/_id/_name/index" */))
const _f9f09ed2 = () => interopDefault(import('../pages/galeria/album/_id/_name/_img.vue' /* webpackChunkName: "pages/galeria/album/_id/_name/_img" */))
const _41f4ad04 = () => interopDefault(import('../pages/mediatar/albumok/_id/_name.vue' /* webpackChunkName: "pages/mediatar/albumok/_id/_name" */))
const _2d00ba5e = () => interopDefault(import('../pages/mediatar/dalszovegek/_id/_name.vue' /* webpackChunkName: "pages/mediatar/dalszovegek/_id/_name" */))
const _a7a6d730 = () => interopDefault(import('../pages/mediatar/felvetelek/_id/_name.vue' /* webpackChunkName: "pages/mediatar/felvetelek/_id/_name" */))
const _6c006545 = () => interopDefault(import('../pages/tallozo/cikk/_id/_name.vue' /* webpackChunkName: "pages/tallozo/cikk/_id/_name" */))
const _482d6c68 = () => interopDefault(import('../pages/elfelejtett-jelszo/_id/_token/index.vue' /* webpackChunkName: "pages/elfelejtett-jelszo/_id/_token/index" */))
const _066bafe1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5c474c6e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/belepes",
    component: _28a455b4,
    name: "belepes"
  }, {
    path: "/elfelejtett-jelszo",
    component: _3aab0c0c,
    name: "elfelejtett-jelszo"
  }, {
    path: "/fiokom",
    component: _6a80f4fe,
    name: "fiokom"
  }, {
    path: "/folknaptar",
    component: _60da18b4,
    name: "folknaptar"
  }, {
    path: "/folkszemle",
    component: _1a08fe54,
    name: "folkszemle"
  }, {
    path: "/forum",
    component: _338f9733,
    name: "forum"
  }, {
    path: "/galeria",
    component: _8d3375ae,
    name: "galeria"
  }, {
    path: "/radio-stream",
    component: _7f3dabd4,
    name: "radio-stream"
  }, {
    path: "/regisztracio",
    component: _910a0974,
    name: "regisztracio"
  }, {
    path: "/tallozo",
    component: _4a48862e,
    name: "tallozo"
  }, {
    path: "/tamogatas",
    component: _4ef40caf,
    name: "tamogatas"
  }, {
    path: "/admin/albumok",
    component: _edc67746,
    name: "admin-albumok"
  }, {
    path: "/admin/beallitasok",
    component: _700d7e89,
    name: "admin-beallitasok"
  }, {
    path: "/admin/dalszovegek",
    component: _d853fe92,
    name: "admin-dalszovegek"
  }, {
    path: "/admin/eloadok",
    component: _53f9a6b9,
    name: "admin-eloadok"
  }, {
    path: "/admin/esemenyek",
    component: _0263dd5c,
    name: "admin-esemenyek"
  }, {
    path: "/admin/felhasznalok",
    component: _64bb20a8,
    name: "admin-felhasznalok"
  }, {
    path: "/admin/felvetelek",
    component: _28fb80ee,
    name: "admin-felvetelek"
  }, {
    path: "/admin/folkszemle",
    component: _d602fb58,
    name: "admin-folkszemle"
  }, {
    path: "/admin/forum",
    component: _4e379333,
    name: "admin-forum"
  }, {
    path: "/admin/oldalak",
    component: _b2e02a64,
    name: "admin-oldalak"
  }, {
    path: "/admin/radio",
    component: _26aa5ce6,
    name: "admin-radio"
  }, {
    path: "/admin/tallozo",
    component: _29168e2e,
    name: "admin-tallozo"
  }, {
    path: "/admin/tamogatasok",
    component: _06195e8b,
    name: "admin-tamogatasok"
  }, {
    path: "/admin/zeneialbumok",
    component: _368aaa28,
    name: "admin-zeneialbumok"
  }, {
    path: "/esemeny-emlekezteto/leiratkozas",
    component: _2683e62a,
    name: "esemeny-emlekezteto-leiratkozas"
  }, {
    path: "/fiokom/albumaim",
    component: _3e4f22da,
    name: "fiokom-albumaim"
  }, {
    path: "/fiokom/esemenyeim",
    component: _524690da,
    name: "fiokom-esemenyeim"
  }, {
    path: "/fiokom/profilom",
    component: _8490bb04,
    name: "fiokom-profilom"
  }, {
    path: "/folknaptar/naptar",
    component: _df5da60e,
    name: "folknaptar-naptar"
  }, {
    path: "/folkszemle/idezetgyujtemeny",
    component: _6d69b20b,
    name: "folkszemle-idezetgyujtemeny"
  }, {
    path: "/folkszemle/tudasproba",
    component: _e95338f0,
    name: "folkszemle-tudasproba"
  }, {
    path: "/hirlevel/leiratkozas",
    component: _b05642d0,
    name: "hirlevel-leiratkozas"
  }, {
    path: "/mediatar/albumok",
    component: _382107fe,
    name: "mediatar-albumok"
  }, {
    path: "/mediatar/dalszovegek",
    component: _517c465b,
    name: "mediatar-dalszovegek"
  }, {
    path: "/mediatar/felvetelek",
    component: _1aeab565,
    name: "mediatar-felvetelek"
  }, {
    path: "/radio/musorujsag",
    component: _61d1d8e4,
    name: "radio-musorujsag"
  }, {
    path: "/tamogatas/sikeres-tranzakcio",
    component: _4fbcc103,
    name: "tamogatas-sikeres-tranzakcio"
  }, {
    path: "/admin/eroforrasok/eloadasmodok",
    component: _ff51f7ca,
    name: "admin-eroforrasok-eloadasmodok"
  }, {
    path: "/admin/eroforrasok/etnikumok",
    component: _1743fa31,
    name: "admin-eroforrasok-etnikumok"
  }, {
    path: "/admin/eroforrasok/forrasok",
    component: _0b3db89e,
    name: "admin-eroforrasok-forrasok"
  }, {
    path: "/admin/eroforrasok/gyujtok",
    component: _f38be536,
    name: "admin-eroforrasok-gyujtok"
  }, {
    path: "/admin/eroforrasok/jatszhatosag",
    component: _7ed5863b,
    name: "admin-eroforrasok-jatszhatosag"
  }, {
    path: "/admin/eroforrasok/kiadok",
    component: _75426db7,
    name: "admin-eroforrasok-kiadok"
  }, {
    path: "/admin/eroforrasok/megyek",
    component: _5ffa4d0a,
    name: "admin-eroforrasok-megyek"
  }, {
    path: "/admin/eroforrasok/mufajok",
    component: _5e28387f,
    name: "admin-eroforrasok-mufajok"
  }, {
    path: "/admin/eroforrasok/szovegforrasok",
    component: _6e717c21,
    name: "admin-eroforrasok-szovegforrasok"
  }, {
    path: "/admin/eroforrasok/tajegysegek",
    component: _42ed22c6,
    name: "admin-eroforrasok-tajegysegek"
  }, {
    path: "/admin/eroforrasok/telepulesek",
    component: _213b486d,
    name: "admin-eroforrasok-telepulesek"
  }, {
    path: "/admin/eroforrasok/temak",
    component: _5cfffdc2,
    name: "admin-eroforrasok-temak"
  }, {
    path: "/admin/esemenyek/listak",
    component: _6b00130a,
    name: "admin-esemenyek-listak"
  }, {
    path: "/admin/folkszemle/idezetgyujtemeny",
    component: _58a6a3ea,
    name: "admin-folkszemle-idezetgyujtemeny"
  }, {
    path: "/admin/folkszemle/tudasproba",
    component: _a2f340f0,
    name: "admin-folkszemle-tudasproba"
  }, {
    path: "/admin/radio/szinkron",
    component: _1d5a462a,
    name: "admin-radio-szinkron"
  }, {
    path: "/admin/esemenyek/listak/lista/:id?",
    component: _188cef52,
    name: "admin-esemenyek-listak-lista-id"
  }, {
    path: "/admin/folkszemle/idezetgyujtemeny/idezet/:id?",
    component: _c4c5c3fe,
    name: "admin-folkszemle-idezetgyujtemeny-idezet-id"
  }, {
    path: "/admin/folkszemle/tudasproba/kerdes/:id?",
    component: _37d0069f,
    name: "admin-folkszemle-tudasproba-kerdes-id"
  }, {
    path: "/admin/eloadok/eloado/:id?",
    component: _c59a5750,
    name: "admin-eloadok-eloado-id"
  }, {
    path: "/admin/felhasznalok/felhasznalo/:id?",
    component: _7a129e28,
    name: "admin-felhasznalok-felhasznalo-id"
  }, {
    path: "/admin/folkszemle/cikk/:id?",
    component: _1821865f,
    name: "admin-folkszemle-cikk-id"
  }, {
    path: "/admin/oldalak/oldal/:id?",
    component: _09f60539,
    name: "admin-oldalak-oldal-id"
  }, {
    path: "/admin/tallozo/cikk/:id?",
    component: _828968ac,
    name: "admin-tallozo-cikk-id"
  }, {
    path: "/admin/zeneialbumok/album/:id?",
    component: _0a7b0c70,
    name: "admin-zeneialbumok-album-id"
  }, {
    path: "/admin/dalszovegek/dalszoveg/:id?/:recording?",
    component: _494bb8bc,
    name: "admin-dalszovegek-dalszoveg-id-recording"
  }, {
    path: "/admin/felvetelek/felvetel/:id?/:album?",
    component: _58496a68,
    name: "admin-felvetelek-felvetel-id-album"
  }, {
    path: "/folkszemle/idezetgyujtemeny/idezet/:id?/:name?",
    component: _81144b48,
    name: "folkszemle-idezetgyujtemeny-idezet-id-name"
  }, {
    path: "/admin/forum/:category",
    component: _49e30ec1,
    name: "admin-forum-category"
  }, {
    path: "/admin/radio/:id",
    component: _f1134410,
    name: "admin-radio-id"
  }, {
    path: "/fiokom/album/:id?",
    component: _77b09349,
    name: "fiokom-album-id"
  }, {
    path: "/radio/musorujsag/:day",
    component: _d83ee1d8,
    name: "radio-musorujsag-day"
  }, {
    path: "/tamogatas/sikertelen-tranzakcio/:type?",
    component: _9cd80f3c,
    name: "tamogatas-sikertelen-tranzakcio-type"
  }, {
    path: "/admin/forum/:category/:theme",
    component: _70e0b4eb,
    name: "admin-forum-category-theme"
  }, {
    path: "/fiokom/esemeny/:id?/:klon?",
    component: _0f85b8f8,
    name: "fiokom-esemeny-id-klon"
  }, {
    path: "/folknaptar/esemeny/:id?/:name",
    component: _4f5c7576,
    name: "folknaptar-esemeny-id-name"
  }, {
    path: "/folknaptar/listak/:slug?/:year?",
    component: _0ffd549e,
    name: "folknaptar-listak-slug-year"
  }, {
    path: "/folkszemle/cikk/:id?/:name?",
    component: _a042e78c,
    name: "folkszemle-cikk-id-name"
  }, {
    path: "/forum/tema/:id?/:name?",
    component: _5fd69f7a,
    name: "forum-tema-id-name"
  }, {
    path: "/galeria/album/:id?/:name?",
    component: _ba01e968,
    children: [{
      path: "",
      component: _571e2e62,
      name: "galeria-album-id-name"
    }, {
      path: ":img",
      component: _f9f09ed2,
      name: "galeria-album-id-name-img"
    }]
  }, {
    path: "/mediatar/albumok/:id/:name?",
    component: _41f4ad04,
    name: "mediatar-albumok-id-name"
  }, {
    path: "/mediatar/dalszovegek/:id/:name?",
    component: _2d00ba5e,
    name: "mediatar-dalszovegek-id-name"
  }, {
    path: "/mediatar/felvetelek/:id/:name?",
    component: _a7a6d730,
    name: "mediatar-felvetelek-id-name"
  }, {
    path: "/tallozo/cikk/:id?/:name?",
    component: _6c006545,
    name: "tallozo-cikk-id-name"
  }, {
    path: "/elfelejtett-jelszo/:id?/:token",
    component: _482d6c68,
    name: "elfelejtett-jelszo-id-token"
  }, {
    path: "/",
    component: _066bafe1,
    name: "index"
  }, {
    path: "/*",
    component: _5c474c6e,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
